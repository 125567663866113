/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: rgba(238,238,228, .0);
  --card-background-lighter-color: #FF99FF;
  --main-text-color:#3CBA8B;
  --title-text-color:#3CBA8B;
}

body{

  background: url("img/background.png");
  background-size: cover;
  font-family: 'Patrick Hand', cursive !important;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
color: #3CBA8B;
}



